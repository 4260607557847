
.order-now-home{
    margin-top: 10vh;
    display: flex;
    width: 100%;
    height: 90vh;
    align-items:center;
    justify-content: center;
}
.order-now-home-divs{
    width: 50%;
} 
.order-now{
    margin-top: 50vh;
}
.svg{
    display: flex;
    align-items: center;
    justify-content: center;
}
.svg img{
    width: 70%;
}
.content h1{
    font-size: 3vw;
    color: var(--primary-color);
}
.content h2{
    font-size: 2vw;
}
.content p{
    font-size: 1.2vw;
}
.benifits-parrent{
    width: 100%;
    height: 60vh;
    margin-bottom: 8vw;
    padding-left: 1vw;
    padding-right: 1vw;

}
.order-now-benifit{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.benifits h2{
    color: var(--primary-color);
    font-size: 3vw;
    margin-left: 0.5vw;
}
.benifits ul{
    margin-left: 2vw;
}
.benifits li{
    font-size: 1.8vw;
}


/* Medium */
@media screen and (min-width: 481px) {}

/* Large */
@media screen and (max-width: 900px) {
    .order-now-home{
        flex-direction: column;
        height: auto;
    }
    .order-now-home-divs{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .svg{
        display: none;
    }
    .content h1{
        font-size: 6vw;
    }
    .content h2{
        font-size: 4vw;
    }
    .content p{
        font-size: 3vw;
    }
    .benifits h2{
        font-size: 6vw;
        margin-left: 0.5vw;
    }
    .benifits ul{
        margin-left: 2vw;
    }
    .benifits li{
        font-size: 4vw;
    }
}


/* Extra Large */
@media screen and (min-width: 1025) {}

/* XXL */
@media screen and (min-width: 1201) {}